import React from 'react';
import { Link, graphql } from 'gatsby';
import Layout from '../layouts/index';
import SEO from '../components/SEO';
import TestimonialImages from '../components/TestimonialImages';
import { getImage, GatsbyImage } from "gatsby-plugin-image"
const Testimonial = ({ data: { prismicTestimonials } }) => {
  const { data,uid } = prismicTestimonials;
  const {
    images,
    feature_image,
    seo_image,
    title
  } = data;
  const seoData = {
    title: `Testimonial - ${data.title.text}`,
    pathname: `/testimonials/${uid}`,
    image: seo_image,
  }
  return (
    <Layout bodyClass="page-testimonial">
      <SEO {...seoData} />
      <div className="intro">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>Testimonals</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <Link to="/testimonials/" aria-label="Back to Testimonials" className="">
          <i style={{ fontSize: '2em' }}
             className="fas fa-arrow-circle-left"/></Link>
        <div className="row justify-content-md-center">
          <div className="col col-lg-8 col-12">
            <div className="testimonial testimonial-single">
              <h1 className="title">{data.title.text}</h1>
              <br/>

              <div className="content" dangerouslySetInnerHTML={{ __html: data.testimonial.html }}/>
            </div>
            
            {
              feature_image.url && (
                <GatsbyImage image={getImage(feature_image)} backgroundColor="black" loading="eager"
                     objectPosition="100% 100%" className="img img-cover"/>)
            }
            <div className="row align-items-start justify-content-center">
              {images.map((userImg, index) => (
                <TestimonialImages key={index} publicURL={userImg.image.url}
                                   alt={userImg.image.alt}
                                   image={userImg.image.gatsbyImageData}
                                   totalCount={images.length}
                                   perRow={data.images_per_row}
                                   orderNum={index}/>

              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="m-5"></div>
    </Layout>
  );
};

export const query = graphql`
query($id: String!) {
  prismicTestimonials(id: {eq: $id}) {
    id
    uid
    data {
      date
      title {
        text
      }
      feature_image {
        url
      }
      images_per_row
      images {
        image {
          gatsbyImageData(
            height: 300
            width: 240
          )
          alt
          url
        }
      }
      seo_image {
        thumbnails {
          twitter {
            url
          }
          facebook {
            url
          }
        }
        dimensions {
          width
          height
        }
        url
      }
      testimonial {
        html
      }
    }
  }
}
`;

export default Testimonial;
