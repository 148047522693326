import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';


class TestimonialImages extends React.Component {
  constructor(props) {
    super(props);
    if (props.perRow && props.perRow <= props.totalCount) {
      this.colWidth = (12 / props.perRow)
      this.className =  'col-6 col-md-' + this.colWidth + '  order-' + this.props.orderNum
    } else {
      this.smallCol = '7'
      this.modifier = props.totalCount % 2;
      this.colWidth = (12 / props.totalCount) - this.modifier;
      this.className = 'col-7 col-md-' + this.colWidth + '  order-' + this.props.orderNum
    }
  }

  render() {
    return (
      <div className={this.className}>
      <GatsbyImage className="img-fluid img-thumbnail" image={this.props.image} about={this.props.alt} alt={this.props.alt} />
      </div>
    );
  }
}

export default TestimonialImages;
